// import {useAppContext, PagegrowlerInstance} from '../libreria/context';
import { useAppContext } from '@pagegrowler_nextjs';
import { PagegrowlerBackendInstance } from '@pagegrowler_nextjs_ssr';
import { getSuscriptionPrice, getBucketsHeaders, getPriceRural, getEnabledCrossContent, getBucketByIdFromOle, getEnabledElleMagazines, getBucketElleMagazines, getLastCover, getEnabledMostRead, getDataByFetch } from 'helpers';

export async function getServerSideProps({ req, res }) {
    let pagegrowlerBackendInstance = PagegrowlerBackendInstance();
    let context = {
        query: { match: [''] }
    }

    const pagegrowlerFrontendApiUrl = process.env.NEXT_PUBLIC_PAGEGROWLER_FRONTEND_API;
    pagegrowlerBackendInstance.setPagegrowlerFrontendApiUrl(pagegrowlerFrontendApiUrl);

    const result = await pagegrowlerBackendInstance.handleGetServerSideProps(context, false);
    const suscriptionPrice = await getSuscriptionPrice();

    if(result?.props?.matchConfig?.menu) {
        result.props.matchConfig.menu.suscriptionPrice = suscriptionPrice;
    }    

    if (!result.notFound) {
        res.setHeader('Last-Modified', result.props.matchConfig.landing.lastModified);
        if (!result?.props?.matchConfig?.properties) {
            result.props.matchConfig.properties = {};
        }
        //Get buckets header
        const { opinion, ultimomento, urgent } = await getBucketsHeaders(true);        

        result.props.matchConfig.properties.bucketsHeader = {
            opinion: (opinion !== false)? opinion : [],
            liveblogs: result.props.matchConfig?.menu?.liveblogs?.item?.items ?? [],
            ultimomento: (ultimomento !== false)? ultimomento : [],
            urgent: (urgent !== false)? urgent : [],
        }

        
        const enableCrossContent = getEnabledCrossContent(result.props.matchConfig.content.boxes)
        if(enableCrossContent) {
            const dataCrossContent = await getBucketByIdFromOle('ole-en-clarin');
            result.props.matchConfig.properties.crossContent = dataCrossContent;
        }        
        const enableElleMagazines = getEnabledElleMagazines(result.props.matchConfig.content.boxes)
        if(enableElleMagazines) {
            const dataElleMagazines = await getBucketElleMagazines();
            result.props.matchConfig.properties.elleMagazines = dataElleMagazines;
        }    
        const enableMostRead = getEnabledMostRead(result.props.matchConfig.content.boxes)
        if(enableMostRead) {
            const url_mostReadNews= `${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/viewed/?type=lilanews&limit=6&provider=bi`; 
            const mostReadNews =  await getDataByFetch(`${url_mostReadNews}`, true);
            
            result.props.matchConfig.properties.mostReadNewsWidget = (mostReadNews !== false && mostReadNews.items)
                ? mostReadNews.items
                : [];
        }
        
        const lastCover = await getLastCover();
        result.props.matchConfig.properties.lastCover = lastCover?.data ?? [];
    }

    return result;
}

const Match = ({ matchConfig }) => {
    let Pagegrowler = useAppContext().pagegrowler;
    return Pagegrowler.handleMatch(matchConfig);
}

export default Match;